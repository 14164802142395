import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import StructureView from '../../views/structure/view'

function Structure (props) {
  const { structure } = props.data
  return (
    <Layout title={structure.name1}>
      <SEO metadata={structure} />
      <StructureView structure={structure} />
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String!) {
    structure(uid: { eq: $uid }) {
      name1
      name2
      name3
      preferredName
      address
      constructionStart
      constructionEnd
      description
      architects {
        displayName
        essayContent
        image {
          publicURL
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
            thumb: gatsbyImageData(
              height: 525
              placeholder: BLURRED
            )
          }
        }
      }
      codeType
      buildingType
      numFloors
      heroImage {
        publicURL
        childImageSharp {
          full: gatsbyImageData(layout: FULL_WIDTH)
          thumb: gatsbyImageData(
            height: 625
            placeholder: BLURRED
          )
        }
      }
      heroName
      galleries {
        gallery
        name
        location {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 150
              height: 150
              breakpoints: 150
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default Structure
