import Gallery from '@browniebroke/gatsby-image-gallery'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from '../../assets/css/structure.module.css'

const Architect = (architect) => {
  return (
    <div>
      <h4>{architect.name}</h4>
      {architect.image &&
      <Gallery colWidth='100' mdColWidth='100' images={[architect.image.childImageSharp]} />}
      <p className={styles.architectDesc} dangerouslySetInnerHTML={{ __html: architect.essayContent }} />
    </div>
  )
}

const setGalleryData = (galleries) => {
  const imageGalleries = new Map()
  galleries.forEach(function (gallery) {
    let images = []
    if (imageGalleries.has(gallery.gallery)) {
      images = imageGalleries.get(gallery.gallery)
    }
    const imageData = gallery.location.childImageSharp
    imageData.thumbAlt = gallery.name
    imageData.title = `${gallery.gallery} - ${gallery.name}`
    images.push(imageData)
    imageGalleries.set(gallery.gallery, images)
  })
  const elements = []
  imageGalleries.forEach((value, key, index) => (
    elements.push((
      <section key={index}>
        <h4>{key}</h4>
        <Gallery images={value} />
      </section>
    ))
  ))
  return [Array.from(imageGalleries.keys()).join(', '), elements]
}

const setHeroData = (heroName, heroImage) => {
  const imageData = []
  if (heroImage) {
    const image = heroImage.childImageSharp
    image.thumbAlt = heroName
    image.title = heroName
    imageData.push(image)
  }
  return imageData
}

const setStructureDetails = (architects, structure) => {
  const structureDetails = []
  if (architects) {
    structureDetails.push({ term: 'Architects:', desc: architects })
  }
  if (structure.name1) {
    structureDetails.push({ term: 'Building Name:', desc: structure.name1 })
  }
  if (structure.name1) {
    structureDetails.push({ term: 'Preferred Name:', desc: structure.preferredName })
  }
  if (structure.address) {
    structureDetails.push({ term: 'Address:', desc: structure.address })
  }
  if (structure.codeType) {
    structureDetails.push({ term: 'Code Type:', desc: structure.codeType })
  }
  if (structure.buildingType) {
    structureDetails.push({ term: 'Building Type:', desc: structure.buildingType })
  }
  if (structure.constructionStart) {
    structureDetails.push({ term: 'Start of Construction:', desc: structure.constructionStart })
  }
  if (structure.constructionEnd) {
    structureDetails.push({ term: 'End of Construction:', desc: structure.constructionEnd })
  }
  if (structure.numFloors) {
    structureDetails.push({ term: 'Floors:', desc: structure.numFloors })
  }
  return structureDetails
}

function StructureView ({ structure }) {
  const architects = structure.architects.map(a => a.displayName).join(', ')
  const structureDetails = setStructureDetails(architects, structure)
  const archiveInventory = []
  archiveInventory.push({ term: 'Location:', desc: 'Seaside Archives, University of Notre Dame' })
  const heroData = setHeroData(structure.heroName, structure.heroImage)
  const [galleryNames, galleryData] = setGalleryData(structure.galleries)
  archiveInventory.push({ term: 'Content:', desc: galleryNames })
  let constructionTimeframe = structure.constructionStart
  if (structure.constructionEnd) {
    constructionTimeframe += ` - ${structure.constructionEnd}`
  }
  return (
    <section>
      <h1>{structure.name1}</h1>
      <section>
        <h3>{structure.address}
          {constructionTimeframe.length > 0 &&
          <span><br/>{constructionTimeframe}</span>
          }
        </h3>
        <h3>{architects}</h3>
        {heroData.length > 0 &&
          <div>
            <Gallery colWidth='100' mdColWidth='100' images={heroData} />
          </div>
        }
        {structure.description &&
        <section>
          <h3>Project Description</h3>
          <p className={styles.projectDesc} dangerouslySetInnerHTML={{ __html: structure.description }} />
        </section>
        }
        <section>
          <h3>Project Specifications</h3>
          <dl className={styles.structureList}>
            {structureDetails.map(detail => (
              <React.Fragment key={detail.term}>
                <dt>{detail.term}</dt>
                <dd>{detail.desc}</dd>
              </React.Fragment>
            ))}
          </dl>
        </section>
        &nbsp;
        <section>
          <h3>Architects</h3>
          {structure.architects.map(a => (
            <React.Fragment key={a.displayName}>
              <Architect name={a.displayName} essayContent={a.essayContent} image={a.image} />
            </React.Fragment>
          ))}
        </section>
        <section>
          <h3>Archive Inventory</h3>
          <dl className={styles.structureList}>
            {archiveInventory.map(detail => (
              <React.Fragment key={detail.term}>
                <dt>{detail.term}</dt>
                <dd>{detail.desc}</dd>
              </React.Fragment>
            ))}
          </dl>
        </section>
        &nbsp;
        <section>
          <h3>Gallery</h3>
          {galleryData.map((gallery, index) => (
            <React.Fragment key={index}>
              {gallery}
            </React.Fragment>
          ))}
        </section>
      </section>
    </section>
  )
}

StructureView.propTypes = {
  structure: PropTypes.shape({
    name1: PropTypes.string.isRequired,
    name2: PropTypes.string,
    name3: PropTypes.string,
    codeType: PropTypes.string,
    buildingType: PropTypes.string,
    lot: PropTypes.string,
    block: PropTypes.string,
    section: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string.isRequired,
    essayContent: PropTypes.string,
    constructionStart: PropTypes.string,
    constructionEnd: PropTypes.string,
    numFloors: PropTypes.string,
    architects: PropTypes.arrayOf(PropTypes.object),
    heroName: PropTypes.string,
    heroImage: PropTypes.object,
    galleries: PropTypes.arrayOf(PropTypes.object),
  }),
}

Architect.propTypes = {
  architect: PropTypes.exact({
    name: PropTypes.string.isRequired,
    essayContent: PropTypes.string,
    image: PropTypes.object,
  }),
}

export default StructureView
