import React from 'react'
import PropTypes from 'prop-types'
import { JsonLd } from '../JsonLd'

export default function Schema ({ name1, pathname, address, numFloors, description, codeType, constructionEnd, image }) {
  if (pathname.includes('/structures/'))
    return (
      <JsonLd>
        {{
          '@context': 'https://schema.org',
          '@type': 'SingleFamilyResidence',
          'name': name1,
          'description': description,
          'url': `https://seaside.library.nd.edu${pathname}`,
          'floorLevel': numFloors,
          'permittedUsage': codeType,
          'petsAllowed': true,
          'yearBuilt': constructionEnd,
          'image': image,
          'containedInPlace': 'Seaside Community',
          'address': {
            '@type': 'PostalAddress',
            'addressCountry': 'USA',
            'addressLocality': 'Seaside',
            'addressRegion': 'Florida',
            'postalCode': '32459',
            'streetAddress': address,
          },
        }}
      </JsonLd>
    )
  return null
}

Schema.propTypes = {
  name1: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  numFloors: PropTypes.string,
  description: PropTypes.string,
  codeType: PropTypes.string.isRequired,
  constructionEnd: PropTypes.string,
  image: PropTypes.string.isRequired,
}
